.btn {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  user-select: none;
  border: 1px solid transparent;
  border-radius: 4px;
}

.btn-primary {
  color: #fff;
  background-color: #0E7BBA;
  border-color: #0b5f8f;
  box-shadow: inset 0 -3px 0 #0c6ba2;
  transition: 80ms;

  &:hover, &:focus {
    color: #f2f2f2;
    background-color: #0c6599;
    border-color: #084669;
    box-shadow: inset 0 -3px 0 #094c73;
    outline: none;
    transition: 80ms;
  }

  &[disabled] {
    background-color: darken(#0E7BBA, 25%);
    border-color: darken(#0b5f8f, 25%);
    pointer-events: none;
    color: #eee;
  }
}
