h1, h2 {
  font-family: aleoregular, serif;
}

h1, h2, h3 {
  font-weight: 400;
  color: #555;
  margin: 20px 0 10px;
}

h2 {
  font-size: 36px;
}
