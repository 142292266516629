[class^="uxf-"],
[class*=" uxf-"],
.uxf-icon {
  font-family: 'UX-Framework';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  position: relative;
  line-height: 1;
  top: 1px;
  display: inline-block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="uxf-"]:empty,
[class*=" uxf-"]:empty,
.uxf-icon:empty {
  width: 1em;
}

.btn [class^="uxf-"],
.btn [class*=" uxf-"],
.btn .uxf-icon {
  top: 0px;
}
.uxf-zoom-in:before {
  content: "\61";
}

.uxf-zoom-out:before {
  content: "\62";
}

.uxf-alert:before {
  content: "\63";
}

.uxf-graduation:before {
  content: "\64";
}

.uxf-newspaper:before {
  content: "\65";
}

.uxf-list:before {
  content: "\66";
}

.uxf-menu:before {
  content: "\67";
}

.uxf-book:before {
  content: "\68";
}

.uxf-doc:before {
  content: "\69";
}

.uxf-doc-inverse:before {
  content: "\6a";
}

.uxf-download:before {
  content: "\6b";
}

.uxf-close-square:before {
  content: "\6c";
}

.uxf-close-circle:before {
  content: "\6d";
}

.uxf-close:before {
  content: "\6e";
}

.uxf-close-tag:before {
  content: "\6f";
}

.uxf-folder:before {
  content: "\70";
}

.uxf-share:before {
  content: "\71";
}

.uxf-bookmarks:before {
  content: "\72";
}

.uxf-bookmark:before {
  content: "\73";
}

.uxf-attach:before {
  content: "\74";
}

.uxf-arrows-rotate:before {
  content: "\75";
}

.uxf-arrow-combo:before {
  content: "\76";
}

.uxf-attention:before {
  content: "\77";
}

.uxf-box:before {
  content: "\78";
}

.uxf-book-alt:before {
  content: "\79";
}

.uxf-disabled:before {
  content: "\7a";
}

.uxf-check:before {
  content: "\41";
}

.uxf-chart-line:before {
  content: "\42";
}

.uxf-chart-pie:before {
  content: "\43";
}

.uxf-chart-bar:before {
  content: "\44";
}

.uxf-chart-area:before {
  content: "\45";
}

.uxf-code:before {
  content: "\46";
}

.uxf-settings:before {
  content: "\47";
}

.uxf-comment:before {
  content: "\48";
}

.uxf-database:before {
  content: "\49";
}

.uxf-down-caret:before {
  content: "\4a";
}

.uxf-down-open:before {
  content: "\4b";
}

.uxf-down-open-large:before {
  content: "\4c";
}

.uxf-down-open-mini:before {
  content: "\4d";
}

.uxf-down-long:before {
  content: "\4e";
}

.uxf-export:before {
  content: "\4f";
}

.uxf-save:before {
  content: "\50";
}

.uxf-flag:before {
  content: "\51";
}

.uxf-flash:before {
  content: "\52";
}

.uxf-heart:before {
  content: "\53";
}

.uxf-heart-empty:before {
  content: "\54";
}

.uxf-help:before {
  content: "\55";
}

.uxf-help-circle:before {
  content: "\56";
}

.uxf-grid-small:before {
  content: "\57";
}

.uxf-help-buoy:before {
  content: "\58";
}

.uxf-level-up:before {
  content: "\59";
}

.uxf-left-down:before {
  content: "\5a";
}

.uxf-left-long:before {
  content: "\30";
}

.uxf-left-open-mini:before {
  content: "\31";
}

.uxf-left-open-large:before {
  content: "\32";
}

.uxf-left-caret:before {
  content: "\33";
}

.uxf-left-open:before {
  content: "\34";
}

.uxf-left-bold:before {
  content: "\35";
}

.uxf-left:before {
  content: "\36";
}

.uxf-megaphone:before {
  content: "\37";
}

.uxf-map:before {
  content: "\38";
}
.uxf-mail:before {
  content: "\39";
}

.uxf-lock:before {
  content: "\21";
}

.uxf-unlock:before {
  content: "\22";
}

.uxf-login:before {
  content: "\23";
}

.uxf-logout:before {
  content: "\24";
}

.uxf-link:before {
  content: "\25";
}

.uxf-location:before {
  content: "\26";
}

.uxf-minus:before {
  content: "\27";
}

.uxf-minus-circle:before {
  content: "\28";
}

.uxf-reply-all:before {
  content: "\29";
}

.uxf-reply:before {
  content: "\2a";
}

.uxf-right-open-mini:before {
  content: "\2b";
}

.uxf-right-caret:before {
  content: "\2c";
}

.uxf-right-open:before {
  content: "\2d";
}

.uxf-right-open-large:before {
  content: "\2e";
}

.uxf-right-bold:before {
  content: "\2f";
}

.uxf-right:before {
  content: "\3a";
}
.uxf-search:before {
  content: "\3b";
}

.uxf-up-caret:before {
  content: "\3c";
}
.uxf-up-open:before {
  content: "\3d";
}

.uxf-up-open-large:before {
  content: "\3e";
}

.uxf-up-open-mini:before {
  content: "\3f";
}

.uxf-up-long:before {
  content: "\40";
}

.uxf-upload-cloud:before {
  content: "\5b";
}

.uxf-upload:before {
  content: "\5d";
}

.uxf-vcard:before {
  content: "\5e";
}

.uxf-users:before {
  content: "\5f";
}

.uxf-user-add:before {
  content: "\60";
}

.uxf-user:before {
  content: "\7b";
}

.uxf-up-bold:before {
  content: "\7c";
}

.uxf-up:before {
  content: "\7d";
}

.uxf-tools:before {
  content: "\7e";
}

.uxf-suitcase:before {
  content: "\5c";
}

.uxf-stop:before {
  content: "\e000";
}

.uxf-star-empty:before {
  content: "\e001";
}

.uxf-star:before {
  content: "\e002";
}

.uxf-right-long:before {
  content: "\e003";
}

.uxf-resize-small:before {
  content: "\e004";
}

.uxf-resize-large:before {
  content: "\e005";
}

.uxf-record:before {
  content: "\e006";
}

.uxf-plus-circle:before {
  content: "\e007";
}

.uxf-plus:before {
  content: "\e008";
}

.uxf-play:before {
  content: "\e009";
}

.uxf-quote:before {
  content: "\e00a";
}

.uxf-publish:before {
  content: "\e00b";
}

.uxf-print:before {
  content: "\e00c";
}

.uxf-popup:before {
  content: "\e00d";
}

.uxf-pencil:before {
  content: "\e00e";
}

.uxf-monitor:before {
  content: "\e00f";
}

.uxf-mobile:before {
  content: "\e010";
}

.uxf-loop:before {
  content: "\e011";
}

.uxf-keyboard:before {
  content: "\e012";
}

.uxf-bulb:before {
  content: "\e013";
}

.uxf-key:before {
  content: "\e014";
}

.uxf-home:before {
  content: "\e015";
}

.uxf-info:before {
  content: "\e016";
}

.uxf-info-circle:before {
  content: "\e017";
}

.uxf-hourglass:before {
  content: "\e018";
}

.uxf-inbox:before {
  content: "\e019";
}

.uxf-forward:before {
  content: "\e01a";
}

.uxf-rewind:before {
  content: "\e01b";
}

.uxf-fast-forward:before {
  content: "\e01c";
}

.uxf-guage:before {
  content: "\e01d";
}

.uxf-infinite:before {
  content: "\e01e";
}

.uxf-dot-three:before {
  content: "\e01f";
}

.uxf-dot-two:before {
  content: "\e020";
}

.uxf-dot-one:before {
  content: "\e021";
}

.uxf-down:before {
  content: "\e022";
}

.uxf-down-black:before {
  content: "\e023";
}

.uxf-direction:before {
  content: "\e024";
}

.uxf-clockwise:before {
  content: "\e025";
}

.uxf-compass:before {
  content: "\e026";
}

.uxf-drive:before {
  content: "\e027";
}

.uxf-counter-clockwise:before {
  content: "\e028";
}

.uxf-clipboard:before {
  content: "\e029";
}

.uxf-clock:before {
  content: "\e02a";
}

.uxf-chat:before {
  content: "\e02b";
}

.uxf-cal:before {
  content: "\e02c";
}

.uxf-back:before {
  content: "\e02d";
}

.uxf-sign:before {
  content: "\e02e";
}

.uxf-cloud:before {
  content: "\e02f";
}

.uxf-cloud-lightning:before {
  content: "\e030";
}

.uxf-tag:before {
  content: "\e031";
}

.uxf-tags:before {
  content: "\e032";
}

.uxf-align-center:before {
  content: "\e033";
}

.uxf-align-justify:before {
  content: "\e034";
}

.uxf-align-left:before {
  content: "\e035";
}

.uxf-align-right:before {
  content: "\e036";
}

.uxf-background-color:before {
  content: "\e037";
}

.uxf-bold:before {
  content: "\e038";
}

.uxf-italic:before {
  content: "\e039";
}

.uxf-text-color:before {
  content: "\e03a";
}

.uxf-underline:before {
  content: "\e03b";
}

.uxf-pin:before {
  content: "\e03c";
}

.uxf-rss:before {
  content: "\e03d";
}

.uxf-external-link:before {
  content: "\e03e";
}

.uxf-facebook-square:before {
  content: "\e03f";
}

.uxf-facebook-circle:before {
  content: "\e040";
}

.uxf-facebook:before {
  content: "\e041";
}

.uxf-twitter:before {
  content: "\e042";
}

.uxf-twitter-circle:before {
  content: "\e043";
}

.uxf-linkedin:before {
  content: "\e044";
}

.uxf-linkedin-circle:before {
  content: "\e045";
}

.uxf-phone:before {
  content: "\e046";
}

.uxf-basket:before {
  content: "\e047";
}

.uxf-globe:before {
  content: "\e048";
}

.uxf-music:before {
  content: "\e049";
}

.uxf-note:before {
  content: "\e04a";
}

.uxf-note-alt:before {
  content: "\e04b";
}

.uxf-wrench:before {
  content: "\e04c";
}

.uxf-filter:before {
  content: "\e04d";
}

.uxf-trash:before {
  content: "\e04e";
}

.uxf-signal:before {
  content: "\e04f";
}

.uxf-shuffle:before {
  content: "\e050";
}

.uxf-trophy:before {
  content: "\e051";
}

.uxf-switch:before {
  content: "\e052";
}

.uxf-instagram:before {
  content: "\e053";
}

.uxf-pinterest-circle:before {
  content: "\e054";
}

.uxf-pinterest:before {
  content: "\e055";
}

.uxf-archive:before {
  content: "\e056";
}

.uxf-back-in-time:before {
  content: "\e057";
}

.uxf-camera:before {
  content: "\e058";
}

.uxf-gplus:before {
  content: "\e059";
}

.uxf-gplus-circle:before {
  content: "\e05a";
}

.uxf-mic:before {
  content: "\e05b";
}

.uxf-pause:before {
  content: "\e05c";
}

.uxf-pictures:before {
  content: "\e05d";
}

.uxf-thumbs-down:before {
  content: "\e05e";
}

.uxf-thumbs-up:before {
  content: "\e05f";
}

.uxf-grid-alt:before {
  content: "\e060";
}

.uxf-grid:before {
  content: "\e061";
}

.uxf-video:before {
  content: "\e062";
}

.uxf-video-alt:before {
  content: "\e063";
}

.uxf-film:before {
  content: "\e064";
}

.uxf-doc-delete:before {
  content: "\e065";
}

.uxf-doc-add:before {
  content: "\e066";
}

.uxf-alt-inverse:before {
  content: "\e067";
}

.uxf-doc-blank:before {
  content: "\e068";
}

.uxf-docs:before {
  content: "\e069";
}

.uxf-blank-inverse:before {
  content: "\e06a";
}

.uxf-doc-alt:before {
  content: "\e06b";
}

.uxf-doc-pdf:before {
  content: "\e06c";
}

.uxf-watch:before {
  content: "\e06d";
}

.uxf-folder-add:before {
  content: "\e06e";
}

.uxf-folder-delete:before {
  content: "\e06f";
}

.uxf-to-start:before {
  content: "\e070";
}

.uxf-to-end:before {
  content: "\e071";
}