//== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
//** Deprecated `$screen-xs` as of v3.0.1
$screen-xs:                  480px;
//** Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min:              $screen-xs;
//** Deprecated `$screen-phone` as of v3.0.1
$screen-phone:               $screen-xs-min;

// Small screen / tablet
//** Deprecated `$screen-sm` as of v3.0.1
$screen-sm:                  768px;
$screen-sm-min:              $screen-sm;
//** Deprecated `$screen-tablet` as of v3.0.1
$screen-tablet:              $screen-sm-min;

// Medium screen / desktop
//** Deprecated `$screen-md` as of v3.0.1
$screen-md:                  992px;
$screen-md-min:              $screen-md;
//** Deprecated `$screen-desktop` as of v3.0.1
$screen-desktop:             $screen-md-min;

// Large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
$screen-lg:                  1200px;
$screen-lg-min:              $screen-lg;
//** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop:          $screen-lg-min;

// Extra large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
$screen-xl:                  1500px;
$screen-xl-min:              $screen-xl;
//** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop:          $screen-xl-min;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max:              ($screen-sm-min - 1);
$screen-sm-max:              ($screen-md-min - 1);
$screen-md-max:              ($screen-lg-min - 1);
$screen-lg-max:              ($screen-xl-min - 1);


$contentMaxWidth: 1390px;

// font-families
$headerTextFont: 'aleoregular', serif;

// base colors
// -----  DO NOT USE BASE COLORS OUTSIDE THIS FILE ---- //
$black: #000;
$white: #fff;
$silver: #eee;
$light-black: #555;
// ----- END OF BASE COLORS ------ //

$skipLinkTextColor: $white;
$skipLinkBackgroundColor: $black;
$skipLinkBorderColor: yellow;

$defaultLinkTextColor: #2A5DB0;
$defaultLinkTextFocusColor: #1b3c72;
$appHeaderBackgroundColor: #0E7BBA;
$appHeaderNavbarBorderColor: #0c6599;
$appHeaderLogoBaseBackgroundColor: #0a5986;
$appHeaderLogoMultiBackgroundColor: linear-gradient(51deg, #0a5986 0%, #0a5986 50%, #0c679c 50%, #0c679c 100%);
$appHeaderMenuActiveBorderColor: #0E7BBA;
$appHeaderSubMenuLinkHoverBackgroundColor: #0d6fa7;
$appHeaderMobileSubMenuBackgroundColor: #0a59a6;
$appHeaderMobileActiveLinkBackgroundColor: #0a4075;
$appHeaderMobileMenuToggleButonBackgroundColor: #0a4075;

$appHeaderLogoTextColor: $white;
$appHeaderMenuBackgroundColor: $silver;
$appHeaderMenuTextColor: $light-black;
$appHeaderMenuLinkHoverBackgroundColor: #ccc;
$appHeaderSubMenuTextColor: $white;

$appTextColor: $light-black;
$appBackgroundColor: $white;

$appFooterBackgroundColor: #494949;
$appFooterTextColor: $white;
$appLegalTextBackgroundColor: #555;
$appLegalLinkColor: #FDFDD9;

$aboutBannerTextColor: $white;
$aboutBannerBackgroundColorLeft: rgb(25,62,120);
$aboutBannerBackgroundColorCenter: rgba(10,74,133,1);
$aboutBannerBackgroundColorRight: rgba(12,117,179,1);
$aboutBannerBrowseLinkColor: $white;
$aboutBannerBrowseLinkBackgroundColor: #353535;
$aboutBannerBrowseLinkBorderColor: $aboutBannerBrowseLinkColor;
$aboutBannerBrowseLinkBackgroundFocusColor: #666;
$aboutContentTextColor: #555;

$mapControlIconColor: #0f7bba;
$mapControlIconHoverColor: #0a5986;
