/* You can add global styles to this file, and also import other style files */

@import "styles/reset/styles";
@import "styles/theme";
@import "styles/ux-proquest/styles";

html, body {
  width: 100%;
  height: 100%;
}

body {
  font-family: 'Roboto', Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.5;
  color: $appTextColor;
  background-color: $appBackgroundColor;
}

a {
  color: $defaultLinkTextColor;
  text-decoration: none;
}
a:hover,
a:focus {
  color: $defaultLinkTextFocusColor;
  text-decoration: underline;
}
a:focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

#container-app {
  display: flex;
  height: 100%;
  flex-direction: column;
}

// loader
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid $defaultLinkTextColor;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: $defaultLinkTextColor transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
